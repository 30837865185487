import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import { KfAnchorLink } from '@klickinc/kf-react-components';
import Loadable from "@loadable/component"
import Cta from '@components/content/Cta';
import Hero from '@components/content/hero/Hero';
import Image from '@components/utility/Image';

// styles
import './styles.scss';

const VideoPlayer = Loadable(() => import("@components/content/VideoPlayer.jsx"))

const Resources = () => {
	return (
		<Layout>
			<Seo title='Resources for Your Patients | EMFLAZA® (deflazacort)' description='Get resources to facilitate your treatment of patients affected by Duchenne muscular dystrophy. View full Prescribing Information & Important Safety Information.' />
			<div className="md:container 2xl:mx-auto page--resources xl:pr-48">
				<Hero
					heroImage='hero-6.png'
					heroImageMobile='hero-6-mobile.png'
					breadcrumbs={['Resources']}
					title='Resources available for your practice'
					addedClass='py-2'
				>
					The following resources can help facilitate your treatment of patients affected
					by Duchenne. Download the <KfAnchorLink url='/prescription-start-form.pdf' target="_blank" linkClass='link z-5 font-bold text-base'>Prescription Start Form</KfAnchorLink> <br className='hidden md:inline' /> and patient support materials below.
				</Hero>

				<div className="max-w-4xl px-8 text-emflaza-blue-600 md:px-0">

					{/* SECTION 1 START */}
					<div className='relative'>
						{/* BG IMAGE START */}
						<div className="absolute section-bg-1 -left-8 -right-8 md:left-0 -top-16 md:-top-24 md:right-auto z-negative">
							<Image objectFit="contain" imageName='page-6-bg-1.png' imageNameMobile='page-6-bg-1-mobile.png' alt=""></Image>
						</div>
						{/* BG IMAGE END */}

						{/* INNER CONTENT START */}
						<div className="flex flex-wrap items-center justify-center gap-0 my-24 md:mt-0 md:mb-14 md:ml-24">
							<div className='flex-auto text-center md:flex-1 max-w-[9.375rem] md:max-w-none desktop:mt-4 3xl:mt-16'>
								<Image objectFit="contain" imageName='page-6-section-1-image.png' alt="PTC Cares™ Brochure"></Image>
							</div>

							<div className='flex-1'>
								<h2 className='my-1 text-2xl font-bold uppercase text-emflaza-blue-400 md:my-3'>PTC <em>Cares</em><sup className='top-[-0.25em]'>&#8482;</sup> Brochure</h2>
								<p className='my-3'>
									Everything you need to know about the
									prescription process and patient assistance programs for EMFLAZA.
								</p>
								<div className='text-center md:text-left'>
									<KfAnchorLink url='/PTC-cares-brochure.pdf' target="_blank" linkClass='z-0 btn my-3 uppercase'>Download</KfAnchorLink>
								</div>
							</div>
						</div>
						{/* INNER CONTENT END */}
					</div>
					{/* SECTION 1 END */}


					{/* SECTION 2 START */}
					<div className='relative'>
						{/* BG IMAGE START */}
						<div className="absolute section-bg-2 -left-8 md:-left-40 -right-8 -top-8 md:top-5 md:right-auto z-negative">
							<Image imageName='page-6-bg-2.png' imageNameMobile='page-6-bg-2-mobile.png' alt=""></Image>
						</div>
						{/* BG IMAGE END */}

						{/* INNER CONTENT START */}
						<div className="flex flex-wrap items-center justify-center gap-0 my-36 mb-60 md:mb-32 md:ml-24 xl:mb-60 md:mt-0 3xl:mb-48 3xl:pt-24">

							<div className='flex-auto text-center md:flex-1 max-w-[12.5rem] md:max-w-none'>
								<Image imageName='page-6-section-2-image.png' imageNameMobile='page-6-section-2-image-mobile.png' alt="Prescription Start Form"></Image>
							</div>

							<div className='flex-1'>
								<h2 className='my-1 text-2xl font-bold uppercase text-emflaza-blue-400 md:my-3'>Prescription Start Form</h2>
								<p className='my-3'>
									Get your patients started on EMFLAZA by completing the form and faxing it to PTC <span className='italic'>Cares</span>.
								</p>
								<div className='text-center md:text-left'>
									<KfAnchorLink url='/prescription-start-form.pdf' target="_blank" linkClass='btn z-0 my-3 uppercase'>Download</KfAnchorLink>
								</div>
							</div>
						</div>
						{/* INNER CONTENT END */}
					</div>
					{/* SECTION 2 END */}



					{/* DAW Guide */}
					<div className='relative daw-guide'>
						{/* BG IMAGE START */}
						<div className="absolute section-bg-1 -left-8 -right-8 md:left-0 -top-16 md:-top-24 md:right-auto z-negative desktop:-top-[14rem]">
							<Image objectFit="contain" imageName='page-6-bg-1.png' imageNameMobile='page-6-bg-1-mobile.png' alt=""></Image>
						</div>
						{/* BG IMAGE END */}

						{/* INNER CONTENT START */}
						<div className='relative desktop:h-[28.75rem] 2xl:h-[32.125rem] 2.5xl:h-[40.625rem]'>
							<div className="flex flex-wrap items-center justify-center gap-0 my-24 md:mt-0 md:mb-14 md:ml-24 desktop:absolute desktop:-top-[6.5rem] 2xl:-top-[6.563rem] 2.5xl:top-[4.688rem]">
								<div className='flex-auto text-center md:flex-1 max-w-[9.375rem] md:max-w-none'>
									<Image objectFit="contain" imageName='hcp-daw-thumbnail.png' imageNameMobile='hcp-daw-thumbnail.png' alt=""></Image>
								</div>

								<div className='flex-1'>
									<h2 className='my-1 text-2xl font-bold uppercase text-emflaza-blue-400 md:my-3'>DISPENSE AS WRITTEN (DAW) STATE-BY-STATE GUIDE</h2>
									<p className='my-3'>
										Understand the DAW guidelines for prescribing EMFLAZA in your state.
									</p>
									<div className='text-center md:text-left'>
										<KfAnchorLink url='/dispense-as-written-guide.pdf' target="_blank" linkClass='z-0 btn my-3 uppercase'>Download</KfAnchorLink>
									</div>
								</div>
							</div>

						</div>
						{/* INNER CONTENT END */}
					</div>
					{/* END DAW Guide */}






					{/* SECTION 3 START */}
					<div className='relative md:flex md:flex-col md:justify-end xl:min-h-[71.875rem] 3xl:min-h-[81.25rem]'>
						{/* BG IMAGE START */}
						<div className="absolute section-bg-3 -left-8 -right-8 -top-16 xl:-top-52 2xl:-top-64 md:-top-24 z-negative">
							<Image imageName='page-6-bg-3.png' imageNameMobile='page-6-bg-3-mobile.png' alt=""></Image>
						</div>
						{/* BG IMAGE END */}

						{/* INNER CONTENT START */}
							<div className="section-3-inner flex flex-wrap items-center gap-0 my-36 md:mt-0 md:mb-14 md:absolute md:top-24 max-w-[21.875rem] pt-8">
								<h2 className='my-3 text-4xl font-bold uppercase text-emflaza-white-100 '>Resources available for your patients</h2>
								<p className='text-base md:text-lg'>
									By providing your patients/caregivers with these educational materials,
									you can help them better understand EMFLAZA. Download these materials
									and pass them on to families living with DMD.
								</p>
							</div>

							{/* Caregiver START */}
							<div className="flex flex-wrap items-center">
								<div className='block md:hidden'>
									<Image imageName='page-6-section-3-image.png' alt="Caregiver family information card"></Image>
								</div>
								<div className='flex-1 md:max-w-[21.875rem]'>
									<h2 className='my-3 text-2xl font-bold uppercase text-emflaza-blue-400'>Caregiver Family Information Guide</h2>
									<p className='my-3'>
										Clear and concise information about the benefits of EMFLAZA and PTC <span className="italic">Cares</span>. Inside, there is a helpful discussion guide as well.
									</p>
									<div className='text-center md:text-left'>
										<KfAnchorLink url='/caregiver-guide.pdf' target="_blank" linkClass='btn my-3 uppercase'>Download</KfAnchorLink>
									</div>
								</div>

								<div className='hidden md:block md:mb-[-18.75rem]'>
									<Image imageName='page-6-section-3-image.png' addedClass='image--caregiver' alt="Caregiver family information card"></Image>
								</div>
							</div>
							{/* Caregiver END */}

							{/* Medication Card Form START */}
							<div className="flex flex-wrap items-center my-24 md:my-44">
								<div className='block md:hidden'>
									<Image imageName='page-6-section-3-image-2.png' imageNameMobile='page-6-section-3-image-2-mobile.png' alt="Medication information card"></Image>
								</div>

								<div className='flex-1 md:max-w-[21.875rem]'>
									<h2 className='my-3 text-2xl font-bold uppercase text-emflaza-blue-400'>Medication <br />Information card</h2>
									<p className='my-3'>
										Designed for caregivers to record the patient's medical history and current medications in one place.
									</p>
									<div className='text-center md:text-left'>
										<KfAnchorLink url='/medication-information-card.pdf' target="_blank" linkClass='my-3 btn'>DOWNLOAD</KfAnchorLink>
									</div>
								</div>

								<div className='hidden md:block md:mb-[-18.75rem]'>
									<Image imageName='page-6-section-3-image-2.png' imageNameMobile='page-6-section-3-image-2-mobile.png' alt="Medication information card"></Image>
								</div>
							</div>
							{/* Medication Card Form START */}
						{/* INNER CONTENT END */}
					</div>
					{/* SECTION 3 END */}


					{/* SECTION 4 START */}
					<div className='relative'>
						{/* BG IMAGE START */}
						<div className="absolute section-bg-4 -left-8 -right-8 -top-12 md:top-0 md:-left-20 2xl:-left-52 3xl:-left-1/3 z-negative">
							<Image imageName='page-6-bg-4.png' imageNameMobile='page-6-bg-4-mobile.png' alt=""></Image>
						</div>
						{/* BG IMAGE END */}

						{/* INNER CONTENT START */}
						<div className="section-4-inner md:flex md:flex-col my-20 md:my-0 md:justify-center md:max-w-[25rem] md:min-h-[25rem]">
							<h2 className='my-3 text-4xl font-bold uppercase text-emflaza-white-100'>Duchenne communities</h2>
							<p className='text-base md:text-lg'>
								<span className='font-bold uppercase'>
									Help patients and caregivers find a support group in
									their communities
								</span> <br />
								There are many organizations created to help families
								living with Duchenne.
							</p>
						</div>
					</div>
					{/* SECTION 4 END */}


					{/* SECTION 5 START */}
					<div className='flex flex-col my-20'>
						<div className='my-10'>
							<h2 className='my-3 text-2xl font-bold uppercase text-emflaza-blue-400'>PTC Peer Navigator Program</h2>
							<p className='my-3'>
								This program connects new patients and their families to families familiar
								with EMFLAZA who can provide support and guidance.
								<br />
								<br />
								To be connected with a PTC Peer Navigator, email <span><KfAnchorLink url="mailto:peernavigator@ptcbio.com" linkClass='underline'>peernavigator@ptcbio.com</KfAnchorLink> or call <KfAnchorLink url="tel:8662825873" linkClass='underline'>1&#8209;866&#8209;282&#8209;5873</KfAnchorLink>.</span>
							</p>
						</div>

						<div className='md:my-5'>
							<h2 className='text-2xl italic font-bold uppercase text-emflaza-blue-400 my-7'>CureDuchenne</h2>

							<div className='flex flex-wrap items-start justify-center md:justify-between md:gap-20'>
								<p className='flex-1'>
									This nonprofit organization supports Duchenne-related research
									and features events, workshops, and webinars on their site.
									Visit <KfAnchorLink linkClass='underline' url='https://www.cureduchenne.org' target='_blank'>www.cureduchenne.org</KfAnchorLink> to learn more.
								</p>
								<KfAnchorLink url='https://www.cureduchenne.org/' target='_blank' linkClass='btn my-6 md:my-0'>VISIT</KfAnchorLink>
							</div>
						</div>

						<div className='md:my-5'>
							<h2 className='text-2xl italic font-bold uppercase text-emflaza-blue-400 my-7'>Muscular Dystrophy (MDA)</h2>

							<div className='flex flex-wrap items-start justify-center md:justify-between md:gap-20'>
								<p className='flex-1'>
									There are more than 150 MDA care clinics in the United States and Puerto Rico with each location home to a full team of healthcare professionals.
									To find a specialized MDA clinic near you, visit <KfAnchorLink url="https://www.mda.org/locate" target="_blank" linkClass="underline">www.MDA.org/locate</KfAnchorLink> or call <KfAnchorLink url="tel:8005721717" linkClass="underline">1&#8209;800&#8209;572&#8209;1717</KfAnchorLink>.
								</p>
								<KfAnchorLink url='https://www.mda.org/locate' target="_blank" linkClass='btn my-6 md:my-0'>VISIT</KfAnchorLink>
							</div>
						</div>

						<div className='md:my-5'>
							<h2 className='text-2xl italic font-bold uppercase text-emflaza-blue-400 my-7'>
								Parent Project Muscular <br className='hidden md:inline' /> Dystrophy (PPMD) Certified <br className='hidden md:inline' /> Care Centers
							</h2>

							<div className='flex flex-wrap items-start justify-center md:justify-between md:gap-20'>
								<p className='flex-1'>
									PPMD centers provide comprehensive care and services specifically for people living with Duchenne muscular dystrophy. These centers strive to provide accessible and comprehensive care to as many patients and families as possible. Centers certified by PPMD can be found at <KfAnchorLink url='https://www.parentprojectmd.org/carecenters' linkClass='underline' target="_blank">www.parentprojectmd.org/carecenters</KfAnchorLink>.
								</p>
								<KfAnchorLink url='https://www.parentprojectmd.org/carecenters' target='_blank' linkClass='btn my-6 md:my-0'>VISIT</KfAnchorLink>
							</div>
						</div>

						<p className='text-xs md:my-5'>
							PTC does not warrant, whether expressed or implied, that the list of centers is accurate or complete. PTC does not recommend or endorse any center, hospital, or healthcare professional included above for any purpose, nor is the list to verify a center’s, hospital’s, or specific healthcare professional's credentials or qualifications. It is your responsibility to select a center, hospital, and/or healthcare professional appropriate for your needs, and you assume full responsibility for your interactions with any center, hospital, or healthcare professional you contact through the center list. PTC is not responsible for the medical care or advice given by any center, hospital, or healthcare professional you choose to contact.
						</p>

						<div className='mt-20'>
							<h2 className='uppercase font-black text-emflaza-blue-600 text-4xl mb-10 max-w-[25rem]'>PTC <span className='italic'>cares</span> patient Support</h2>
							<h3 className='font-bold uppercase'>
								PTC <span className='italic'>Cares</span> helps your patients gain access to EMFLAZA
							</h3>
							<p>
								PTC <span className='italic'>Cares</span> is a patient support program dedicated to helping your patients gain access to EMFLAZA. Our team of dedicated case managers are here to offer support and answer any questions you may have about accessing medication, support, services, and financial assistance programs.
							</p>

							<h2 className='uppercase text-emflaza-blue-400 font-bold text-2xl my-10 max-w-[28.125rem]'>
								Understanding <br className='inline md:hidden' /> PTC <span className='italic'>Cares</span> and the prescription process
							</h2>

							<p>
								Once you and your patient have decided on EMFLAZA, help get them started on treatment by understanding the prescription process of PTC <span className='italic'>Cares</span>.
							</p>

							<div className='video'>
								<VideoPlayer src='/videos/ptc-cares.mp4' poster='/images/ptc-thumbnail.jpg' title='What is PTC Cares?'></VideoPlayer>
							</div>
						</div>
					</div>
					{/* SECTION 5 END */}

					{/* SECTION 6 END */}
					<div className='relative'>
						{/* BG IMAGE START */}
						<div className="absolute section-bg-6 -left-8 md:-left-20 -right-8 -top-16 md:top-5 md:right-auto">
							<Image imageName='page-6-bg-5.png' imageNameMobile='page-6-bg-5-mobile.png' alt=""></Image>
						</div>
						{/* BG IMAGE END */}

						{/* INNER CONTENT START */}
						<div className="flex flex-wrap items-center gap-0 my-36 md:mt-0 md:justify-end min-h-[25rem] 3xl:min-h-[34.375rem] relative">
							<div className='flex-auto text-center md:mt-28 md:mr-96'>
								<Image imageName='page-6-ptc-logo.png' alt="PTC Cares™" addedClass="max-w-[18.313rem] my-0 mx-auto"/>
							</div>

							<div>
								<div className='md:flex md:gap-5'>
									<div className='hidden mb-5 text-center md:block'>
										<Image imageName='page-6-phone-icon.png' imageNameMobile='page-6-phone-icon.png' alt="" addedClass="max-w-[5.813rem]"/>
									</div>
									<div>
										<h2 className='text-left text-emflaza-blue-400 font-bold text-2xl my-5 max-w-[31.25rem]'>
											<span className='uppercase'>For Information about PTC <span className='italic'>Cares</span></span>
											<br />
											Call <KfAnchorLink url="tel:8444782227" target="_blank" linkClass="underline">1&#8209;844&#8209;478&#8209;2227</KfAnchorLink>
											<br />
											Monday — Friday <br className='inline md:hidden' />
											8 <span className='text-lg'>AM</span> — 6 <span className='text-lg'>PM</span>, ET
										</h2>
										<div className='hidden md:block'>
											<KfAnchorLink url='https://ptccares.com/' target='_blank' linkClass='btn my-1 md:my-3'>Visit PTCCares.com</KfAnchorLink>
										</div>
									</div>
									<div className='block mb-5 text-center md:hidden'>
										<Image imageName='page-6-phone-icon.png' imageNameMobile='page-6-phone-icon.png' alt="" addedClass="max-w-[5.813rem] m-auto"/>
									</div>
									<div className='text-center md:hidden'>
										<KfAnchorLink url='https://ptccares.com/' target='_blank' linkClass='btn my-1 md:my-3'>Visit PTCCares.com</KfAnchorLink>
									</div>
								</div>
							</div>
						</div>
						{/* INNER CONTENT END */}
					</div>
					{/* SECTION 6 END */}

					<div className="flex flex-wrap mt-20 mb-8 space-y-5 md:space-y-0 md:space-x-5">
						<Cta ctaUrl='/efficacy/real-world-data' iconUrl={'cta-world-icon-2.png'} ctaText='Real-World Data' addedClass='cta flex-1' iconAddedClass='max-w-[63px]'/>
						<Cta ctaUrl='/getting-started/switch-considerations' iconUrl={'cta-s-icon.png'} ctaText='Switching Corticosteroids' addedClass='cta flex-1'/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Resources;
